<template>
  <div class="timeline-container" :class="{'hz': type === 'horizontal', 'vt': type === 'vertical'}">
    <div class="timeline"></div>

    <ul class="timeline-wrapper">

      <li v-for="(item, item_index) in items"
          :class="{'cursor-pointer': item.event}"
          :key="item_index"
          @click="handleEvent(item)">

        <div class="process-marker"
             :class="{'s': item.status === 'SUCCESS', 'd': item.status === 'DANGER', 'l': item.status === 'LOADING'}">

          <vs-icon v-if="item.status === 'SUCCESS'" icon="icon-check" icon-pack="feather"/>

          <vs-icon v-if="item.status === 'DANGER'" icon="icon-x" icon-pack="feather"/>

          <vs-icon v-if="item.status === 'LOADING'" icon="icon-refresh-cw" icon-pack="feather"/>
        </div>

        <div class="process-title" v-if="item.title.length">
          {{item.title}}
        </div>
      </li>
    </ul>


  </div>
</template>

<script>
export default {
  name: 'TimeLine',
  props: {
    type: {
      type: String,
      default: 'horizontal'
    },
    items: {
      type: Array,
      default: () => { return [] }
    }
  },
  methods: {
    handleEvent (item) {
      if (item.event) {
        this.$emit(item.event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.timeline-container {
  width: 100%;
  height: 70px;
  padding: 7px 0;
  position: relative;

  .timeline {
    height: 1px;
    width: 100%;
    background-color: #cecece;
    position: absolute;
    top: 25px;
    bottom: 0;
    margin: auto;
  }

  ul.timeline-wrapper {
    height: 100%;
    display: flex;

    li {
      height: 100%;
      position: relative;
      text-align: center;
      flex-grow: 2;

      .process-marker {
        z-index: 1000;
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 1.4em;
        color: #cecece;
        background-color: #f8f8f8;
        border: 1px solid #cecece;
        border-radius: 50%;
        position: absolute;
        top: 25px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;

        &.s {
          color: #00a862;
          border: 1px solid #00a862;
        }

        &.d {
          color: #b00000;
          border: 1px solid #b00000;
        }

        &.l {
          color: #1e90ff;
          border: 1px solid #1e90ff;
          -webkit-animation: rotating 2s linear infinite;
          -moz-animation: rotating 2s linear infinite;
          -ms-animation: rotating 2s linear infinite;
          -o-animation: rotating 2s linear infinite;
          animation: rotating 2s linear infinite;

          @-webkit-keyframes rotating /* Safari and Chrome */ {
            from {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
            to {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
          }

          @keyframes rotating {
            from {
              -ms-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
            to {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
          }
        }

        &:hover {
          -moz-transform: scale(1.2);
          -webkit-transform: scale(1.2);
          -o-transform: scale(1.2);
          -ms-transform: scale(1.2);
          transform: scale(1.2);
        }
      }

      .process-title {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  /*.timeline-panel {
    margin-top: 20%;
    width: 500px;
    height: 200px;
    background-color: #cbd0df;
    border-radius: 2px;
    position: relative;
    text-align: left;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    float: left;

    &:after {
      content: '';
      position: absolute;
      margin-top: -12%;
      left: 10%;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      border-bottom: 15px solid #cbd0df;
    }
  }*/
}
</style>
